export default async function postProjectPayment(projectId) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/rest/create-checkout-session-package?projectId=${projectId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const url = await response.text();

    if (url) {
      window.location.href = url;
    } else {
      console.error('Location header not found in the response');
    }

    // window.location.href = response.url;
  } catch (error) {
    console.error('Error:', error);
  }
}
