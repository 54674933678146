import React from 'react';

export default function ProgressBar({ status }) {
  const retrieveCurrentStep = () => {
    if (status === 'DRAFT') {
      return 1;
    }
    if (status === 'SUBMITTED') {
      return 2;
    }
    if (status === 'IN_PROGRESS') {
      return 3;
    }
    if (status === 'COMPLETED') {
      return 4;
    }
    return 0;
  };
  return (
    <div className="progress-bar">
      <div
        className={`progress-bar__step progress-bar__step--step-${retrieveCurrentStep(status)}`}
      />
    </div>
  );
}
