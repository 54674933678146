import React from 'react';

export default function ColorOption({ color, selected, onClick, readOnly }) {
  const getSelectedClass = () => {
    if (selected) {
      if (color.checkType === 'dark') {
        return 'color-option__circle--selected--dark';
      }

      return 'color-option__circle--selected--light';
    }

    return '';
  };

  return (
    <div
      className={`color-option ${readOnly ? 'color-option--read-only' : ''}`}
      onClick={() => onClick && onClick(color.id)}
    >
      <div
        className={`color-option__circle ${getSelectedClass()}`}
        style={{ backgroundColor: color.colorCode }}
      />
    </div>
  );
}
