import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';
import ProgressBar from './progressBar';
import ColorLabel from './colorLabel';
import formatDate from '../util/date';

export default function ProjectCard({
  project,
  onClick,
  statusLabel,
  dateCreatedLabel,
  deadLineLabel,
  children,
}) {
  const { t } = useTranslation();

  const retrieveModifier = () => {
    if (project.status === 'DRAFT') {
      return 'warning';
    }
    if (project.status === 'IN_PROGRESS') {
      return 'warning';
    }
    if (project.status === 'COMPLETED') {
      return 'success';
    }
    return '';
  };

  return (
    <div className="project-card">
      <div className="project-card__header">
        <div className="project-card__room">{children}</div>
      </div>
      <div className="project-card__row">
        <div className="project-card__row__item">
          <div className="label">{statusLabel}</div>
          <ProgressBar status={project.status} />
        </div>
        <div className="project-card__row__item">
          <ColorLabel
            text={t(`status.${project.status.toLowerCase()}`)}
            modifier={retrieveModifier()}
          />
        </div>
      </div>
      <div className="project-card__row">
        <div className="project-card__row__item">
          <div className="label">{dateCreatedLabel}</div>
          <div className="project-card__value">{formatDate(project.created)}</div>
        </div>
      </div>
      {project.schedule && (
        <div className="project-card__row">
          <div className="project-card__row__item">
            <div className="label">{deadLineLabel}</div>
            <div className="project-card__value">
              {t(`deadline.${project.schedule.toLowerCase()}`)}
            </div>
          </div>
        </div>
      )}
      <div className="project-card__row">
        <Button
          caption={t('buttons.detailsButton')}
          onClick={() => onClick(project)}
          buttonType="default"
          icon="arrowRightBlack"
        />
      </div>
    </div>
  );
}
