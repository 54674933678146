import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Menu, MenuItem, Sidebar as SidebarComponent } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../ui/assets/logo.svg';
import LanguageSwitcher from './languageSwitcher'; // Import the LanguageSwitcher component

export default function Sidebar() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const { logout, user } = useAuth0();

  const toggleSidebar = () => {
    setToggled(!toggled);
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="sidebar">
      {broken && !toggled && (
        <div>
          <button type="button" onClick={toggleSidebar} className="sidebar__toggle">
            ☰
          </button>
        </div>
      )}
      <SidebarComponent
        breakPoint="md"
        customBreakPoint="850px"
        onBreakPoint={setBroken}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
      >
        {broken && (
          <button type="button" onClick={toggleSidebar} className="sidebar__toggle">
            ☰
          </button>
        )}
        <Menu renderExpandIcon={(e) => console.log(e)}>
          <Logo className="sidebar__logo" />
          <MenuItem component={<Link to="/" />}>Projects</MenuItem>
          <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          <span className="sidebar__username">{user?.name}</span>
          <div className="sidebar__language-switcher">
            <LanguageSwitcher />
          </div>
        </Menu>
      </SidebarComponent>
    </div>
  );
}
