import i18n from '../i18n';

export default function getScheduleOptions() {
  const { t } = i18n;

  return [
    { id: 'FAST', caption: t('deadline.fast') },
    { id: 'NORMAL', caption: t('deadline.normal') },
    { id: 'SLOW', caption: t('deadline.slow') },
  ];
}
