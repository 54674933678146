import React from 'react';

export default function UploadedImages({ images }) {
  return (
    <div className="uploaded-images">
      {images.map((image) => (
        <img key={image.blob} src={image.blob} alt="uploaded" />
      ))}
    </div>
  );
}
