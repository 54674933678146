/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getFile } from '../../api/files';
import { getResource } from '../../api/resource';
import ColorOptionContainer from '../../components/colorOptionsContainer';
import Profile from '../../components/profile';
import PogressBar from '../../components/progressBar';
import RoomOptionContainer from '../../components/roomOptionContainer';
import Sidebar from '../../components/sidebar';
import StyleOptionContainer from '../../components/styleOptionsContainer';
import UploadedImages from '../../components/uploadedImages';
import getBudgetOptions from '../../data/budget';
import getRooms from '../../data/rooms';
import getScheduleOptions from '../../data/schedule';
import getStyles from '../../data/styles';
import { ReactComponent as Calendar } from '../../ui/assets/calendarCheck.svg';
import { ReactComponent as ChartLine } from '../../ui/assets/chartLine.svg';
import { ReactComponent as DoorOpen } from '../../ui/assets/doorOpen.svg';
import { ReactComponent as Flag } from '../../ui/assets/flag.svg';
import { ReactComponent as Ruler } from '../../ui/assets/ruler.svg';
import { ReactComponent as User } from '../../ui/assets/user.svg';
import { ReactComponent as Wallet } from '../../ui/assets/wallet.svg';

export default function ProjectDetails() {
  const [project, setProject] = useState({});
  const [rooms, setRooms] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState('');
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [client, setClient] = useState({});
  //   const [picturesCurrentRoom, setPicturesCurrentRoom] = useState([]);
  const [uploadedStyleImages, setUploadedStyleImages] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const projectResource = await getResource(
            token,
            `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
          );
          if (!projectResource) {
            navigate('/');
            return;
          }
          const [roomResources, clientResource, colors, styleResources, imageResult] =
            await Promise.all([
              getResource(token, projectResource._links.rooms.href),
              getResource(token, projectResource._links.client.href),
              getResource(token, projectResource._links.colors.href),
              getResource(token, projectResource._links.styles.href),
              getResource(token, projectResource._links.styleImages.href),
            ]);

          console.log('imageResult', imageResult);

          const uploadedStyles = await Promise.all(
            imageResult._embedded?.files.map(async (file) => {
              const blob = await getFile(null, file._links.self.href);
              const updatedFile = { ...file, blob };
              updatedFile.href = file._links.self.href;
              return updatedFile;
            }),
          );

          const budgetOptions = getBudgetOptions(t);
          const selectedBudgetOption =
            budgetOptions.find((option) => option.id === projectResource.budget) || {};
          const selectedBudgetCaption = selectedBudgetOption.caption || '';

          const scheduleOptions = getScheduleOptions(t);
          const selectedScheduleOption =
            scheduleOptions.find((option) => option.id === projectResource.schedule) || {};
          const selectedScheduleCaption = selectedScheduleOption.caption || '';

          const styles = getStyles();
          const styleResourcesWithImages = styleResources._embedded.styles.map((styleResource) => {
            const styleWithImage = styles.find((style) => style.imageId === styleResource.imageId);
            return {
              image: styleWithImage.image,
              imageId: styleWithImage.imageId,
            };
          });

          setProject(projectResource);
          setUploadedStyleImages(uploadedStyles);
          setSelectedSchedule(selectedScheduleCaption);
          setSelectedBudget(selectedBudgetCaption);
          setRooms(roomResources._embedded.rooms || []);
          setClient(clientResource);
          setSelectedColors(colors._embedded.colors || []);
          setSelectedStyles(styleResourcesWithImages);
        } catch (error) {
          console.error('Failed to fetch project details:', error);
          navigate('/');
        }
      })();
    } else {
      navigate('/');
    }
  }, [getAccessTokenSilently, id, navigate, t]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderSectionHeader = (icon, labelKey, value) => {
    return (
      <div className="project-details__content__section__header">
        <div className="project-details__content__section__header__icon">{icon}</div>
        <div className="project-details__content__section__header__right-part">
          <h4>{t(`pages.projectDetails.labels.${labelKey}`)}</h4>
          {value && (
            <span className="project-details__content__section__header__large-text">{value}</span>
          )}
        </div>
      </div>
    );
  };

  const getRoomOption = (type) => {
    const roomOptions = getRooms();
    const room = roomOptions.find((option) => option.type === type);
    return room;
  };

  const renderRooms = () => {
    return Array.isArray(rooms)
      ? rooms.map((room) => {
          const roomOption = getRoomOption(room.type);
          return (
            <div className="project-details__content__row" key={room.type}>
              <div className="project-details__content__section">
                {renderSectionHeader(<DoorOpen />, 'room')}
                <div className="project-details__content__section__content">
                  <RoomOptionContainer
                    key={`room-option-${room.id}`}
                    rooms={[roomOption]}
                    selectedRooms={[]}
                    readOnly
                  />
                </div>
              </div>
              <div className="project-details__content__section" key={`dimension-${room.type}`}>
                {renderSectionHeader(<Ruler />, 'roomDimension')}
                <div className="project-details__content__section__content">
                  <div className="project-details__content__section__vertical">
                    <div
                      className="project-details__content__section__horizontal-item"
                      key={`length-${room.id}`}
                    >
                      <label>{t('pages.projectDetails.labels.length')}</label>
                      <span className="project-details__content__section__item-value">
                        {`${room.length} mm`}
                      </span>
                    </div>
                    <div
                      className="project-details__content__section__horizontal-item"
                      key={`width-${room.id}`}
                    >
                      <label>{t('pages.projectDetails.labels.width')}</label>
                      <span className="project-details__content__section__item-value">
                        {`${room.width} mm`}
                      </span>
                    </div>
                    <div
                      className="project-details__content__section__horizontal-item"
                      key={`height-${room.id}`}
                    >
                      <label>{t('pages.projectDetails.labels.height')}</label>
                      <span className="project-details__content__section__item-value">
                        {`${room.height} mm`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      : null;
  };

  const renderColors = () => {
    if (!selectedColors || selectedColors.length === 0) return null;
    return (
      <div className="project-details__content__section__content">
        <ColorOptionContainer colors={selectedColors} readOnly />
      </div>
    );
  };

  const renderSelectedStyles = () => {
    if (!selectedStyles || selectedStyles.length === 0) return null;
    return (
      <div className="project-details__content__section__content">
        <StyleOptionContainer styles={selectedStyles} readOnly />
        <UploadedImages images={uploadedStyleImages} />
      </div>
    );
  };

  return (
    <div className="page-with-sidebar">
      <Sidebar />
      <div className="layout-container">
        <h1 className="project-details__title">{t('pages.projectDetails.title')}</h1>
        <div className="project-details__content">
          <div className="project-details__content__column">
            <div className="project-details__content__row">
              <div className="project-details__content__section">
                {renderSectionHeader(<Wallet />, 'budget', selectedBudget)}
              </div>
              <div className="project-details__content__section">
                {renderSectionHeader(<Calendar />, 'dateCreated', formatDate(project.created))}
              </div>
            </div>
            {rooms.length > 0 && renderRooms()}
            <div className="project-details__content__row">
              <div className="project-details__content__section">
                <div className="project-details__content__section__header">
                  <Ruler />
                  <h4>{t('pages.projectDetails.labels.style')}</h4>
                </div>
                <div>
                  <h4 className="small-bottom-margin">
                    {t('pages.projectDetails.labels.designStyle')}
                  </h4>
                  {renderSelectedStyles()}
                </div>
                <div>
                  <h4 className="small-bottom-margin">{t('pages.projectDetails.labels.color')}</h4>
                  {renderColors()}
                </div>
              </div>
            </div>
          </div>
          <div className="project-details__content__column">
            <div className="project-details__content__row">
              <div className="project-details__content__section">
                {renderSectionHeader(<Flag />, 'deadline', selectedSchedule)}
              </div>
              <div className="project-details__content__section">
                {renderSectionHeader(<ChartLine />, 'progress')}
                <div className="project-details__content__section__content">
                  <PogressBar status={project.status} />
                </div>
              </div>
            </div>
            <div className="project-details__content__row">
              <div className="project-details__content__section">
                {renderSectionHeader(<User />, 'profile')}
                <div className="project-details__content__section__content">
                  <Profile client={client} />
                </div>
              </div>
            </div>
            <div className="project-details__content__row">
              <div className="project-details__content__section">
                <div className="project-details__content__section__header">
                  <Wallet />
                  <h4>{t('pages.projectDetails.labels.description')}</h4>
                </div>
                <div>{project.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
