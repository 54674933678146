import React from 'react';
import getRooms from '../data/rooms';
import ProjectCard from './projectCard';

export default function ProjectCardContainer({
  projects,
  onClick,
  statusLabel,
  dateCreatedLabel,
  deadLineLabel,
}) {
  const getRoomIcons = (project) => {
    const rooms = getRooms();

    return project.rooms.map((room) => {
      const roomForProject = rooms.find((r) => r.type === room.type);
      return (
        <div key={room.type}>
          <roomForProject.icon />
          <div className="project-card-container__room-image__text">{roomForProject.name}</div>
        </div>
      );
    });
  };

  const renderCardComponents = () => {
    return projects.map((project) => {
      return (
        <ProjectCard
          key={project._links.self.href}
          onClick={() => onClick(project)}
          project={project}
          statusLabel={statusLabel}
          dateCreatedLabel={dateCreatedLabel}
          deadLineLabel={deadLineLabel}
        >
          <div className="project-card-container__room-image">{getRoomIcons(project)}</div>
        </ProjectCard>
      );
    });
  };

  return <div className="project-card-container">{renderCardComponents()}</div>;
}
