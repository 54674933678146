import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function RoomOption({ room, selected, onClick, readOnly, children }) {
  return (
    <div
      className={`room-option ${readOnly ? 'room-option--read-only' : ''} ${
        selected ? 'room-option--selected' : ''
      }`}
      onClick={() => !readOnly && onClick(room.type)}
    >
      {!readOnly && (
        <div className="room-option__checkbox">
          {selected && <Checked />}
          {!selected && <Unchecked />}
        </div>
      )}
      <div className="room-option__image">{children}</div>
    </div>
  );
}
