import React from 'react';
import StyleOption from './styleOption';

export default function StyleOptionContainer({ styles, selectedStyles, onClick, readOnly }) {
  const renderStyleOptionComponents = () => {
    return styles.map((style) => (
      <StyleOption
        key={style.imageId}
        selected={
          readOnly
            ? false
            : selectedStyles.find((selectedStyle) => selectedStyle.imageId === style.imageId)
        }
        onClick={readOnly ? () => {} : () => onClick(style)}
        readOnly={readOnly}
      >
        <img src={style.image} alt="" />
      </StyleOption>
    ));
  };
  return (
    <div
      className={`style-options-container block-container ${
        readOnly ? 'style-options-container--read-only' : ''
      }`}
    >
      {renderStyleOptionComponents()}
    </div>
  );
}
