import i18n from '../i18n';

export default function getBudgetOptions() {
  const { t } = i18n;
  return [
    { id: 'SMALL', caption: t('budgetOption.small') },
    { id: 'MEDIUM', caption: t('budgetOption.medium') },
    { id: 'LARGE', caption: t('budgetOption.large') },
    {
      id: 'VERY_LARGE',
      caption: t('budgetOption.extraLarge'),
    },
  ];
}
