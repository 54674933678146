import React from 'react';
import Collapsible from 'react-collapsible';
import { ReactComponent as ArrowDown } from '../ui/assets/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../ui/assets/arrowUp.svg';

function CollapsibleComponent({ title, children, titleStyle }) {
  const getTitle = () => {
    return titleStyle === 'small' ? (
      <div className="collapsible__title--small">{title}</div>
    ) : (
      <h5>{title}</h5>
    );
  };

  return (
    <Collapsible
      trigger={
        <div className="trigger-div">
          {getTitle()}
          <ArrowDown />
        </div>
      }
      triggerWhenOpen={
        <div className="trigger-div">
          {getTitle()}
          <ArrowUp />
        </div>
      }
      transitionTime={200}
    >
      {children}
    </Collapsible>
  );
}

export default CollapsibleComponent;
