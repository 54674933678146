/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function Profile({ client }) {
  return (
    <div className="profile">
      <div className="profile-column">
        <div className="input-group">
          <label htmlFor="firstName">First name</label>
          <span id="firstName" className="profile-value" aria-labelledby="firstName-label">
            {client.firstName}
          </span>
        </div>
        <div className="input-group">
          <label htmlFor="email">Email address</label>
          <span id="email" className="profile-value" aria-labelledby="email-label">
            {client.email}
          </span>
        </div>
      </div>
      <div className="profile-column">
        <div className="input-group">
          <label htmlFor="lastName">Last name</label>
          <span id="lastName" className="profile-value" aria-labelledby="lastName-label">
            {client.lastName}
          </span>
        </div>
        <div className="input-group">
          <label htmlFor="mobile">Mobile/Whatsapp number</label>
          <span id="mobile" className="profile-value" aria-labelledby="mobile-label">
            {client.mobile}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Profile;
