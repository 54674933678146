import React from 'react';
import ColorOption from './colorOption';

export default function ColorOptionsContainer({ colors, selectedColors, onClick, readOnly }) {
  const renderColorOptionComponents = () => {
    return colors.map((color) => {
      return (
        <ColorOption
          key={color.colorCode}
          color={color}
          selected={
            readOnly
              ? false
              : selectedColors.find((selectedColor) => selectedColor.colorCode === color.colorCode)
          }
          onClick={readOnly ? () => {} : () => onClick(color)}
          readOnly={readOnly}
        />
      );
    });
  };

  return (
    <div
      className={`color-options-container block-container ${
        readOnly ? 'color-options-container--read-only' : ''
      }`}
    >
      <div className="color-options-container__inner-container">
        {renderColorOptionComponents()}
      </div>
    </div>
  );
}
