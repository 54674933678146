import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFile, postFile } from '../../api/files';
import postProjectPayment from '../../api/payment';
import {
  getDesignPackagePrices,
  saveDesignPackagePrice,
  sendProjectContinueMessage,
} from '../../api/project';
import { createResource, deleteResource, getResource, patchResource } from '../../api/resource';
import Banner from '../../components/banner';
import Loader from '../../components/loader';
import NavBar from '../../components/navbar';
import Wizard from '../../components/wizard';
import i18n from '../../i18n';
import ColorSelection from '../color-selection/colorSelection';
import CurrentRoom from '../current-room/currentRoom';
import DesignPackageSelection from '../design-package-selection/designPackageSelection';
import PersonalDetails from '../personal-details/personal-details';
import ProjectInformation from '../project-information/project-information';
import RoomSelection from '../room-selection/roomSelection';
import StyleSelection from '../style-selection/styleSelection';
import commitMultiSelectableObjects from './actions/commitMultiSelectableObjects';
import handleClickMultiSelectableObject from './eventHandlers/handleClickMultiSelectableObject';

export default function ProjectSteps({ wizardStep, changeWizardStep }) {
  const [step, setStep] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [project, setProject] = useState({});
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [client, setClient] = useState({});
  const [picturesCurrentRoom, setPicturesCurrentRoom] = useState([]);
  const [uploadedStyleImages, setUploadedStyleImages] = useState([]);
  const [designPackages, setDesignPackages] = useState([]);

  const roomSelection = useRef({
    existing: [],
    active: [],
    new: [],
    toDelete: [],
  });
  const colorSelection = useRef({
    existing: [],
    active: [],
    new: [],
    toDelete: [],
  });
  const styleSelection = useRef({
    existing: [],
    active: [],
    new: [],
    toDelete: [],
  });
  const designPackageSelectionRef = useRef(null);

  const { id } = useParams();

  const getResourceId = (resource) => {
    return resource._links.self.href.split('/').pop();
  };

  const getRooms = async (projectResource) => {
    const rooms = await getResource(null, projectResource._links.rooms.href);
    setSelectedRooms(rooms._embedded?.rooms);
    roomSelection.current = {
      existing: rooms._embedded?.rooms,
      new: [],
      toDelete: [],
    };
  };

  const getClient = async (projectResource) => {
    const clientResource = await getResource(null, projectResource._links.client.href);
    if (!clientResource) {
      const newClient = await createResource(null, `${process.env.REACT_APP_BACKEND_URL}/persons`, {
        language: i18n.language.toUpperCase(),
      });
      await patchResource(null, projectResource._links.self.href, {
        client: newClient._links.self.href,
      });
      setClient(newClient);
      return newClient;
    }
    setClient(clientResource);
    return clientResource;
  };

  const getProject = async () => {
    if (project._links) {
      return project;
    }
    if (id) {
      const projectResource = await getResource(
        null,
        `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
      );
      setProject(projectResource);
      return projectResource;
    }
    const newProject = await createResource(
      null,
      `${process.env.REACT_APP_BACKEND_URL}/projects`,
      {},
    );
    setProject(newProject);
    return newProject;
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const projectResource = await getProject(id);
        getRooms(projectResource);
      })();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (step === 0) {
      changeWizardStep(0);
    }
    if (step > 0 && step < 6) {
      changeWizardStep(1);
    }
    if (step === 6) {
      changeWizardStep(2);
    } else if (step === 7) {
      changeWizardStep(3);
    } else if (step > 7) {
      changeWizardStep(4);
    }
  }, [step, changeWizardStep]);

  const getColors = async () => {
    const colors = await getResource(null, project._links.colors.href);
    setSelectedColors(colors._embedded?.colors);
    colorSelection.current = {
      existing: colors._embedded?.colors,
      new: [],
      toDelete: [],
    };
  };

  const getStyles = async () => {
    const styles = await getResource(null, project._links.styles.href);
    setSelectedStyles(styles._embedded?.styles);
    styleSelection.current = {
      existing: styles._embedded?.styles,
      new: [],
      toDelete: [],
    };
  };

  const getStyleImages = async () => {
    const getImageResult = await getResource(null, project._links.styleImages.href);

    const result = await Promise.all(
      getImageResult._embedded?.files.map(async (file) => {
        const blob = await getFile(null, file._links.self.href);
        const updatedFile = { ...file, blob };
        updatedFile.href = file._links.self.href;
        return updatedFile;
      }),
    );
    setUploadedStyleImages(result);
  };

  const getRoomImages = async () => {
    const getImageResult = await getResource(null, project._links.roomImages.href);

    const result = await Promise.all(
      getImageResult._embedded?.files.map(async (file) => {
        const blob = await getFile(null, file._links.self.href);
        const updatedFile = { ...file, blob };
        updatedFile.href = file._links.self.href;
        return updatedFile;
      }),
    );
    setPicturesCurrentRoom(result);
  };

  const handleClickRoom = (room) => {
    handleClickMultiSelectableObject(room, roomSelection, selectedRooms, setSelectedRooms, 'type');
  };

  const handleClickColor = (color) => {
    handleClickMultiSelectableObject(
      color,
      colorSelection,
      selectedColors,
      setSelectedColors,
      'colorCode',
    );
  };

  const handleClickStyle = (style) => {
    handleClickMultiSelectableObject(
      style,
      styleSelection,
      selectedStyles,
      setSelectedStyles,
      'imageId',
    );
  };

  const handleClickBudget = (budget) => {
    if (selectedBudget.id === budget.id) {
      setSelectedBudget({});
      return;
    }
    setSelectedBudget(budget);
  };

  const handleClickSchedule = (schedule) => {
    if (selectedSchedule.id === schedule.id) {
      setSelectedSchedule({});
      return;
    }
    setSelectedSchedule(schedule);
  };

  const handleChangeUploadedStyleImages = (files) => {
    setUploadedStyleImages([...uploadedStyleImages, ...files]);
  };

  const handleChangePicturesCurrentRoom = (files) => {
    setPicturesCurrentRoom([...picturesCurrentRoom, ...files]);
  };

  const handleChangeProjectInput = (event) => {
    const { name, value } = event.target;
    setProject({ ...project, [name]: value });
  };

  const handleChangeRoomInput = (event, room) => {
    const { name, value } = event.target;
    room[name] = value;
    const rooms = selectedRooms.map((r) => {
      if (r.type === room.type) {
        return room;
      }
      return r;
    });
    setSelectedRooms(rooms);
  };

  const handleChangePersonalDetails = (event) => {
    if (event.target.name === 'termsAccepted') {
      setClient({ ...client, termsAccepted: event.target.checked });
      return;
    }
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const handleClickCheckout = async () => {
    await patchResource(null, client._links.self.href, {
      language: i18n.language.toUpperCase(),
    });
    await postProjectPayment(getResourceId(project));
  };

  const handleSaveExitProject = async (email) => {
    if (client._links) {
      setClient({ ...client, email });
      await patchResource(null, client._links.self.href, {
        email,
        language: i18n.language.toUpperCase(),
      });
      await sendProjectContinueMessage(null, getResourceId(project));
    } else {
      const projectResource = await getProject();
      const clientResource = await getClient(projectResource);
      setClient({ ...clientResource, email });
      await patchResource(null, clientResource._links.self.href, {
        email,
        language: i18n.language.toUpperCase(),
      });
      await sendProjectContinueMessage(null, getResourceId(projectResource));
    }

    window.location.replace('https://mytipee.com');
  };

  const handleClickNextStep = async () => {
    if (step === 1) {
      if (selectedRooms.length === 0) {
        return;
      }

      if (!project._links) {
        const newProject = await createResource(
          null,
          `${process.env.REACT_APP_BACKEND_URL}/projects`,
          {},
        );
        setProject(newProject);
        await commitMultiSelectableObjects(null, roomSelection, newProject, 'rooms', 'type', []);
      } else {
        await commitMultiSelectableObjects(null, roomSelection, project, 'rooms', 'type', []);
        await getColors();
      }
    }

    if (step === 2) {
      await getRooms(project);
      await commitMultiSelectableObjects(null, colorSelection, project, 'colors', 'colorCode', []);
      await getStyles();
      await getStyleImages();
    }
    if (step === 3) {
      const filteredStyleImages = uploadedStyleImages.filter((uploadedImage) => {
        return !uploadedImage._links;
      });

      if (filteredStyleImages.length > 0) {
        setShowLoader(true);

        const fileIds = await Promise.all(filteredStyleImages.map((file) => postFile(null, file)));
        const imageList = fileIds.map(
          (fileId) => `${process.env.REACT_APP_BACKEND_URL}/files/${fileId}`,
        );
        await patchResource(null, project._links.self.href, {
          styleImages: imageList,
        });
        setShowLoader(false);
      }

      await commitMultiSelectableObjects(null, styleSelection, project, 'styles', 'styleName', [
        'imageId',
      ]);

      await getRoomImages();
    }
    if (step === 4) {
      const filteredPictures = picturesCurrentRoom.filter((uploadedImage) => {
        return !uploadedImage._links;
      });

      if (filteredPictures.length > 0) {
        setShowLoader(true);

        const fileIds = await Promise.all(filteredPictures.map((file) => postFile(null, file)));
        const imageList = fileIds.map(
          (fileId) => `${process.env.REACT_APP_BACKEND_URL}/files/${fileId}`,
        );
        await patchResource(null, project._links.self.href, {
          roomImages: imageList,
        });
        getRooms(project);
        setSelectedBudget({ id: project.budget });
        setSelectedSchedule({ id: project.schedule });
        setShowLoader(false);
      }
    }
    if (step === 5) {
      await patchResource(null, project._links.self.href, {
        budget: selectedBudget.id,
        schedule: selectedSchedule.id,
        description: project.description,
      });
      await Promise.all(
        selectedRooms.map((room) => {
          return patchResource(null, room._links.self.href, {
            length: room.length,
            width: room.width,
            height: room.height,
          });
        }),
      );
      await getClient(project);
    }

    if (step === 6) {
      await patchResource(null, client._links.self.href, client);
      const designPackageResources = await getDesignPackagePrices(getResourceId(project));
      setDesignPackages(designPackageResources);
    }

    if (step === 7) {
      await patchResource(null, project._links.self.href, designPackageSelectionRef.current);
      await patchResource(null, project._links.self.href, {
        status: 'SUBMITTED',
        designPackage: designPackageSelectionRef.current.type,
      });
      await saveDesignPackagePrice(getResourceId(project), designPackageSelectionRef.current.type);
      handleClickCheckout();
      return;
    }
    setStep(step + 1);
  };

  const handleClickPreviousStep = async () => {
    if (step === 2 || step === 6) {
      getRooms(project);
    }
    if (step === 3) {
      await getColors();
    }
    if (step === 4) {
      await getStyles();
      if (uploadedStyleImages.some((image) => !image._links)) {
        await getStyleImages();
      }
    }
    if (step === 5) {
      if (picturesCurrentRoom.some((image) => !image._links)) {
        await getRoomImages();
      }
    }
    setStep(step - 1);
  };

  const handleClickPackage = (selectedDesignPackage) => {
    designPackageSelectionRef.current = {
      type: selectedDesignPackage.type,
      price: selectedDesignPackage.price,
      oldPrice: selectedDesignPackage.oldPrice,
    };
    handleClickNextStep();
  };

  const renderComponentForCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          <RoomSelection
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            selectedRooms={selectedRooms}
            onClick={handleClickRoom}
          />
        );
      case 2:
        return (
          <ColorSelection
            selectedColors={selectedColors}
            onClick={handleClickColor}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
          />
        );
      case 3:
        return (
          <StyleSelection
            selectedStyles={selectedStyles}
            onClick={handleClickStyle}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            files={uploadedStyleImages}
            onChangeFiles={handleChangeUploadedStyleImages}
            isButtonDisabled={showLoader}
            onClickDelete={(file) => async () => {
              if (file._links) {
                const projectStyleImages = await getResource(null, project._links.styleImages.href);
                const styleImages = projectStyleImages._embedded.files;
                const styleImage = styleImages.filter(
                  (image) => image._links.self.href !== file._links.self.href,
                );
                await patchResource(null, project._links.self.href, {
                  styleImages: styleImage.map((image) => image._links.self.href),
                });
                await deleteResource(null, file._links.self.href);
              }
              setUploadedStyleImages(uploadedStyleImages.filter((picture) => picture !== file));
            }}
          />
        );
      case 4:
        return (
          <CurrentRoom
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            picturesCurrentRoom={picturesCurrentRoom}
            onChangePicturesCurrentRoom={handleChangePicturesCurrentRoom}
            IsButtonDisabled={showLoader}
            onClickDelete={(file) => async () => {
              if (file._links) {
                const projectRoomImages = await getResource(null, project._links.roomImages.href);
                const roomImages = projectRoomImages._embedded.files;
                const roomImage = roomImages.filter(
                  (image) => image._links.self.href !== file._links.self.href,
                );
                await patchResource(null, project._links.self.href, {
                  roomImages: roomImage.map((image) => image._links.self.href),
                });
                await deleteResource(null, file._links.self.href);
              }
              setPicturesCurrentRoom(picturesCurrentRoom.filter((picture) => picture !== file));
            }}
          />
        );
      case 5:
        return (
          <ProjectInformation
            selectedStyles={selectedStyles}
            onClick={handleClickStyle}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            project={project}
            rooms={selectedRooms}
            selectedBudget={selectedBudget}
            onClickBudget={handleClickBudget}
            selectedSchedule={selectedSchedule}
            onClickSchedule={handleClickSchedule}
            onChangeInputProject={handleChangeProjectInput}
            onChangeInputRoom={handleChangeRoomInput}
          />
        );
      case 6:
        return (
          <PersonalDetails
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            personalDetails={client}
            onChangeInput={handleChangePersonalDetails}
          />
        );
      case 7:
        return (
          <DesignPackageSelection
            onClickPackage={handleClickPackage}
            onClickNextStep={handleClickNextStep}
            onClickPreviousStep={handleClickPreviousStep}
            designPackages={designPackages}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar
        step={wizardStep}
        showWizard
        email={client.email}
        onClickSave={handleSaveExitProject}
      />
      <Banner />
      <div className="project-steps">
        <div className="mobile-wizard">
          <Wizard step={wizardStep} />
        </div>
        <div className="project-steps__inner-container">{renderComponentForCurrentStep()}</div>
        {showLoader && <Loader />}
      </div>
    </>
  );
}
