import React from 'react';
import { ReactComponent as Checked } from '../ui/assets/checked.svg';
import { ReactComponent as Unchecked } from '../ui/assets/unchecked.svg';

export default function ImageOption({ selected, onClick, readOnly, children }) {
  return (
    <div
      className={`style-option ${selected ? 'style-option--selected' : ''}  ${
        readOnly ? 'style-option--read-only' : ''
      }`}
      onClick={() => onClick()}
    >
      {!readOnly && (
        <div className="style-option__checkbox">
          {selected && <Checked />}
          {!selected && <Unchecked />}
        </div>
      )}
      <div className="style-option__image">{children}</div>
    </div>
  );
}
